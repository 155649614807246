<template>
    <div>
        <Navbar page="White Label" link="/cadastros" nameLink="Cadastros" video="https://www.youtube.com/watch?v=TtljzS_OSCE" pdf="/itens_exclusivos.pdf" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <h1 class="text-2xl mb-5">Login com White Label:</h1>

            <div class="flex gap-5">
                <div class="mb-5 border py-2 px-3 2-full md:w-128">
                    <p class="text-base font-semibold">URL login Pessoas na Empresa com White Label: </p>
                    <a class="text-sm underline cursor-pointer" target="_blank" :href="`http://${url}/login/${$store.state.empresa.hash}`">{{ `${url}/login/${$store.state.empresa.hash}` }}</a>
                    <p class="text-sm text-red-700 font-semibold">O endereço acima é o endereço (url) que deverá ser informado para as pessoas na empresa. É com este endereço que elas acessarão a Fácil Gestão LGPD. </p>
                </div>

                <div class="mb-5 border py-2 px-3 2-full md:w-128">
                    <p class="text-base font-semibold">URL login Profissionais com White Label: </p>
                    <a class="text-sm underline cursor-pointer" target="_blank" :href="`http://${url}/login/${$store.state.user.codigoIndicacao}`">{{ `${url}/login/${$store.state.user.codigoIndicacao}` }}</a>
                    <p class="text-sm text-red-700 font-semibold">O endereço acima é o endereço (url) que deverá ser informado para os profissionais. É com este endereço que elas acessarão a Fácil Gestão LGPD. </p>
                </div>
            </div>

            <h1 class="text-2xl mb-5">Personalização Emails:</h1>
            
            <form v-if="form.emailsConfig" action="#" method="POST">
                <div class="grid grid-cols-12 gap-6">
                    <!-- <div class="col-span-12 md:col-span-12">
                        <h4 class="text-base text-blue-700 font-semibold mb-1">
                            Notificar como:
                        </h4>
                        <p class="text-sm text-red-700 font-semibold mb-2">Os emails de notificação deve aparecer em nome da empresa ou do consultor. </p>
                        <label :for="`notificarComo-Empresa`" class="inline-flex items-center">
                            <input type="radio" class="form-radio" :for="`notificarComo-Empresa`" :name="`notificarComo`" v-model="form.emailsConfig.notificarComo" value="Empresa">
                            <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Empresa</span>
                        </label>
                        <label :for="`notificarComo-Consultor`" class="inline-flex items-center ml-1 md:ml-2">
                            <input type="radio" class="form-radio" :for="`notificarComo-Consultor`" :name="`notificarComo`" v-model="form.emailsConfig.notificarComo" value="Consultor">
                            <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Consultor</span>
                        </label>
                    </div> -->
                    <div class="col-span-12 md:col-span-4">
                        <h4 class="text-base text-blue-700 font-semibold mb-3">
                            Importação de base:
                        </h4>
                        <label for="titulo_importacao" class="block text-sm font-medium">Título</label>
                        <input v-model="form.emailsConfig.importacao.titulo" type="text" id="titulo_importacao" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        <label for="descricao_importacao" class="block text-sm font-medium mt-2">Descrição</label>
                        <textarea rows="2" v-model="form.emailsConfig.importacao.descricao" type="text" id="descricao_importacao" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div> 

                    <div class="col-span-12 md:col-span-4">
                        <h4 class="text-base text-blue-700 font-semibold mb-3">
                            Atividade do mapeamento pendente:
                        </h4>
                        <label for="titulo_qmaPendente" class="block text-sm font-medium">Título</label>
                        <input v-model="form.emailsConfig.qmaPendente.titulo" type="text" id="titulo_qmaPendente" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        <label for="descricao_qmaPendente" class="block text-sm font-medium mt-2">Descrição</label>
                        <textarea rows="2" v-model="form.emailsConfig.qmaPendente.descricao" type="text" id="descricao_qmaPendente" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div> 

                    <div class="col-span-12 md:col-span-4">
                        <h4 class="text-base text-blue-700 font-semibold mb-3">
                            Análise de risco pendente:
                        </h4>
                        <label for="titulo_riscoPendente" class="block text-sm font-medium">Título</label>
                        <input v-model="form.emailsConfig.riscoPendente.titulo" type="text" id="titulo_riscoPendente" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        <label for="descricao_riscoPendente" class="block text-sm font-medium mt-2">Descrição</label>
                        <textarea rows="2" v-model="form.emailsConfig.riscoPendente.descricao" type="text" id="descricao_riscoPendente" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div> 

                    <div class="col-span-12 md:col-span-4">
                        <h4 class="text-base text-blue-700 font-semibold mb-3">
                            Diagnósticos pendente:
                        </h4>
                        <label for="titulo_diagnosticoPendente" class="block text-sm font-medium">Título</label>
                        <input v-model="form.emailsConfig.diagnosticoPendente.titulo" type="text" id="titulo_diagnosticoPendente" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        <label for="descricao_diagnosticoPendente" class="block text-sm font-medium mt-2">Descrição</label>
                        <textarea rows="2" v-model="form.emailsConfig.diagnosticoPendente.descricao" type="text" id="descricao_diagnosticoPendente" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div> 

                </div>

                <div class="grid grid-cols-12 gap-6 mt-6">
                    <div class="col-span-12 md:col-span-4">
                        <h4 class="text-base text-blue-700 font-semibold mb-3">
                            Treinamento envio de certificado:
                        </h4>
                        <label for="titulo_treinamentoCertificado" class="block text-sm font-medium">Título</label>
                        <input v-model="form.emailsConfig.treinamentoCertificado.titulo" type="text" id="titulo_treinamentoCertificado" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder="Certificado de participação: #nomedoevento">
                        <label for="descricao_treinamentoCertificado" class="block text-sm font-medium mt-2">Descrição</label>
                        <textarea rows="6" v-model="form.emailsConfig.treinamentoCertificado.descricao" type="text" id="descricao_treinamentoCertificado" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder="Olá, #nomedapessoa#, clique no botão abaixo para visualizar o certificado referente ao evento #nomedoevento#"/>
                    </div> 
                    <div class="col-span-12 md:col-span-4">
                        <h4 class="text-base text-blue-700 font-semibold mb-3">
                            Treinamento envio de avaliação:
                        </h4>
                        <label for="titulo_treinamentoAvaliacao" class="block text-sm font-medium">Título</label>
                        <input v-model="form.emailsConfig.treinamentoAvaliacao.titulo" type="text" id="titulo_treinamentoAvaliacao" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder="Realizar avaliação: #nomedoevento">
                        <label for="descricao_treinamentoAvaliacao" class="block text-sm font-medium mt-2">Descrição</label>
                        <textarea rows="6" v-model="form.emailsConfig.treinamentoAvaliacao.descricao" type="text" id="descricao_treinamentoAvaliacao" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder="Olá, #nomedapessoa#, clique no botão abaixo para realizar a avaliação referente ao #nomedoevento#"/>
                    </div> 
                    <div class="col-span-12 md:col-span-4">
                        <h4 class="text-base text-blue-700 font-semibold mb-3">
                            Treinamento envio de convite:
                        </h4>
                        <label for="titulo_treinamentoConvite" class="block text-sm font-medium">Título</label>
                        <input v-model="form.emailsConfig.treinamentoConvite.titulo" type="text" id="titulo_treinamentoConvite" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder="Certificado de participação: #nomedoevento">
                        <label for="descricao_treinamentoConvite" class="block text-sm font-medium mt-2">Descrição</label>
                        <textarea rows="6" v-model="form.emailsConfig.treinamentoConvite.descricao" type="text" id="descricao_treinamentoConvite" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder=""/>
                    </div> 
                </div>
                
               
            </form>

            <h1 class="text-2xl mb-5">Personalização do Site:</h1>

            <form v-if="form.siteConfig" action="#" method="POST">
                <div class="col-span-12 md:col-span-8">
                    <div class="grid grid-cols-12 gap-3">
                        <div class="col-span-12 md:col-span-6">
                            <label for="arte" class="block text-sm font-medium">Imagem da tela de login</label>
                            <small class="text-xs font-medium">Tamanho recomendado 1920x1080</small>
                            <vue-dropzone ref="doc" id="doc" class="w-full mt-2" :options="dropzoneOptions" v-on:vdropzone-success="update">
                            </vue-dropzone>
                        </div>
                        <div class="col-span-12 md:col-span-6">
                            <img v-if="form.siteConfig.imagem && form.siteConfig.imagem.mimetype" class="border-1 mt-14 h-60 rounded-md" :src="`${url_api}/upload?mimetype=${form.siteConfig.imagem.mimetype}&filename=${form.siteConfig.imagem.filename}&folder=empresas`" />
                        </div>
                    </div>
                </div>

                <div class="col-span-12 md:col-span-4">
                    <label for="frase_site" class="block text-sm font-medium mt-2">Texto da página de login</label>
                    <textarea rows="6" v-model="form.siteConfig.texto" type="text" id="frase_site" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder=""/>
                </div> 
            </form>


            <div class="grid grid-cols-2 mt-5">
                <div class="py-3 text-left">
                    <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
                <div class="py-3 text-right">
                    <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    components: {
        vueDropzone,
    },
    data() {
        return {
            route: 'users',
            form: {
                _id: null,
                emailsConfig: null,
                siteConfig: null,
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste sua arte aqui',
                dictRemoveFile: 'Remover',
                acceptedFiles: 'image/*'
            })
        }
    },
    methods: {
        async update(file, response) {
            this.form.siteConfig.imagem = response.file;
        },
        async save() {
            if(!this.form._id) return this.$vToastify.error("Erro no fluxo");

            const req = await this.$http.put(`/v1/empresas/${this.$store.state.empresa._id}/whitelabel`, this.form);

            if (req.data.success) {
                if(req.data.userLogin && req.data.userLogin._id){
                    if(req.data.token) localStorage.setItem('token', req.data.token);
                    localStorage.setItem('user', JSON.stringify(req.data.userLogin));
                    this.$store.commit('login', req.data.userLogin);
                }
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.push({path: `/cadastros`});
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
    },
    async beforeMount() {
        console.log(this.$store.state.empresa._id);
        const req = await this.$http.get(`/v1/empresas/${this.$store.state.empresa._id}`);
        if(req.data && req.data._id){
            this.form = {
                _id: req.data._id,
                emailsConfig: req.data.emailsConfig,
                siteConfig: req.data.siteConfig,
            }
        }
    },
}
</script>